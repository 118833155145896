body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

#meme {
  margin: auto;
  width: 450px;
  height: 450px;
  background-size: 100%; 
  text-align: center;
  position: relative;
}

p {
  position: absolute; 
  left: 0;
  right: 0; 
  margin: 15px 0;
  padding: 0 5px;
  font-family: impact;
  font-size: 2.5em;
  text-transform: uppercase;
  color: white;
  letter-spacing: 1px;
  text-shadow:2px 2px 0 #000,
  -2px -2px 0 #000,
  2px -2px 0 #000,
  -2px 2px 0 #000,
  0px 2px 0 #000,
  2px 0px 0 #000,
  0px -2px 0 #000,
  -2px 0px 0 #000,
  2px 2px 5px #000;
}

.bottom {
   bottom: 0;
 }

.top {
   top: 0;
 }